<template>
  <div class="operate-plan">
    <div>
      <a-tabs
        type="card"
        v-model="currentTab"
        @change="handleTagsChange"
        :tabBarGutter="10"
        size="large"
        :animated="true"
      >
        <a-space slot="tabBarExtraContent">
          <a-button type="primary" @click="handleShowPlan">计划模板</a-button>
          <a-button type="primary" @click="handleExport" :loading="loadingSpinning || exportLoading">数据导出</a-button>
        </a-space>

        <!-- 周计划 -->
        <a-tab-pane key="week" tab="周计划">
          <div v-if="currentTab == 'week'" style="overflow-y: auto">
            <a-row type="flex" align="middle" justify="space-between" style="margin: 5px 0">
              <a-col :span="10">
                <a-space>
                  <a-input-search
                    style="width: 136px"
                    placeholder="小红书号/昵称"
                    v-model.trim="searchMediaParams.nickname"
                    @search="handleSearchMedia"
                    allowClear
                  ></a-input-search>
                  <a-select
                    v-model="searchMediaParams.operatorId"
                    style="width: 200px"
                    placeholder="运营人"
                    show-search
                    option-filter-prop="children"
                    @change="handleSearchMedia"
                    allowClear
                  >
                    <a-select-option v-for="item in allMemberList" :key="item.id" :value="item.id">{{
                      item.operatorName
                    }}</a-select-option>
                  </a-select>
                  <a-select
                    style="width: 200px"
                    v-model="searchMediaParams.operatorGroupId"
                    placeholder="请选择团队"
                    show-search
                    option-filter-prop="children"
                    allowClear
                    @change="handleSearchMedia"
                  >
                    <a-select-option v-for="item in allTeamList" :key="item.id" :value="item.id">{{
                      item.groupName
                    }}</a-select-option>
                  </a-select>
                </a-space>
              </a-col>
              <a-col :span="10" style="text-align: right; color: #000">
                <a-space>
                  <span>{{ currentWeekDetails.startYear }}</span>
                  <!-- <a-divider type="vertical" /> -->
                  <div>{{ currentWeekDetails.startDate }} ~ {{ currentWeekDetails.endDate }}</div>
                  <a-divider type="vertical" />
                  <div>
                    <a-button type="link" @click="handleBeforeWeek">&lt;</a-button>
                    <a-button type="link" @click="handleThisWeek">本周</a-button>
                    <a-button type="link" @click="handleNextWeek">></a-button>
                  </div>
                  <a-week-picker
                    size="default"
                    placeholder="Select Week"
                    @change="handleWeekChange"
                    :value="currentWeek"
                    :allowClear="false"
                    style="width: 100px"
                  >
                  </a-week-picker>
                  <!-- </div> -->
                </a-space>
              </a-col>
            </a-row>

            <!-- 表头 -->
            <div class="week-row">
              <div class="first-col" style="justify-content: space-between; align-items: center">
                <div>
                  <span style="color: #000">账号</span><span style="margin-left: 10px">{{ mediaTotal }}个</span>
                </div>
                <!-- <a-button type="link" icon="user" @click="showAddMedia = true">+账号</a-button> -->
              </div>
              <div class="week-col" v-for="(v, i) in WEEK_RENDER_LIST" :key="i">
                {{ v.shortDate }} {{ v.text }}
                <div class="today-head-dot" v-show="v.ymdDate == todayDate"></div>
              </div>
            </div>
            <!-- 表数据 -->
            <a-spin :spinning="loadingSpinning" size="large" tip="加载中...">
              <div class="week-row" v-for="(val, ind) in weekDataList" :key="ind">
                <!-- 媒体号 -->
                <div
                  class="first-col border-col"
                  @mouseenter="val.showHover = true"
                  @mouseleave="val.showHover = false"
                >
                  <div class="media-column">
                    <div class="media-left">
                      <img :src="val.mediaData.avatar" alt="头像" referrerpolicy="no-referrer" />
                    </div>
                    <div class="media-right">
                      <span style="margin-left: 15px">
                        {{ val.mediaData.nickname }}
                      </span>
                      <div style="display: flex; justify-content: space-between">
                        <div class="right-txt" style="margin-right: 5px">
                          <div class="logo">
                            <img src="@/assets/icon/xhs_logo.png" alt="" />
                          </div>
                          <div
                            class="author_code_item"
                            v-if="val.mediaData.authorCode"
                            :title="val.mediaData.authorCode"
                          >
                            {{ val.mediaData.authorCode }}
                          </div>
                          <div v-else>-</div>
                        </div>
                        <div class="right-txt">
                          <div class="logo" style="border-radius: 0">
                            <img
                              v-show="val.mediaData.photoUrl"
                              :src="val.mediaData.photoUrl"
                              alt=""
                              referrerpolicy="no-referrer"
                            />
                          </div>
                          <div>{{ personalityTypeObject[val.mediaData.personalityType] }}</div>
                        </div>
                      </div>
                    </div>
                    <a-icon
                      type="arrows-alt"
                      class="open-icon"
                      style="right: 25px"
                      v-show="val.showHover"
                      @click.stop="openDetailDraw(val.mediaData)"
                    />
                    <a-popover placement="rightTop">
                      <template slot="content">
                        <a-space direction="vertical">
                          <a-button block @click="handleCopyWeek(val)">复制周计划</a-button>
                          <a-button block @click="handlePasteWeek(val)" :disabled="!weekCopyData.authorId"
                            >粘贴周计划</a-button
                          >
                          <a-button block @click="handleSavePlanTemplate(val)">保存为模板</a-button>
                          <a-button block @click="handleUsePlanTemplate(val, 'week')">应用计划模板</a-button>
                          <!-- <a-popconfirm
                            placement="top"
                            title="移除媒体号?"
                            ok-text="确认"
                            cancel-text="取消"
                            @confirm="handleDelMedia(val.mediaData.id, 'week')"
                            @cancel="() => {}"
                          >
                            <a-button block>移除媒体号</a-button>
                          </a-popconfirm> -->
                        </a-space>
                      </template>
                      <template slot="title"> </template>
                      <a-icon type="more" class="open-icon" />
                    </a-popover>
                  </div>
                </div>
                <!-- 周数据 -->
                <div
                  :class="['week-col', 'border-col', { 'gray-col': i == 5 || i == 6 }, { 'current-col': v.isCurrent }]"
                  v-for="(v, i) in val.weekData"
                  :key="i"
                  @click="handleWeekColumnClick(v)"
                >
                  <div class="today-col" v-show="v.date == todayDate"></div>
                  <a-dropdown
                    key="week"
                    :trigger="['contextmenu']"
                    style="display: flex; flex-direction: column; align-items: center"
                  >
                    <div style="width: 100%; min-width: 100px; height: 100%; justify-content: center">
                      <draggable
                        style="min-width: 100px; min-height: 33px"
                        :list="v.topicSelectionTagList"
                        :group="{ name: 'week', pull: true, put: true }"
                        filter=".no-drag"
                        @add="(e) => handleWeekDragAdd(e, v, val)"
                        @remove="(e) => handleWeekDragRemove(e, v, val)"
                        @change="(e) => handleWeekDragChange(e, v, val)"
                        @end="handleDragEnd"
                      >
                        <div
                          class="tags"
                          v-for="tag in v.topicSelectionTagList"
                          :key="tag.id"
                          :style="`background-color: ${tag.colorHex}`"
                        >
                          <template v-if="tag.topic">
                            <a-tooltip>
                              <template slot="title">
                                <div style="white-space: break-spaces">{{ tag.topic }}</div>
                              </template>
                              <a-icon type="info-circle" style="color: #fff; margin-right: 5px" theme="filled" />
                              <span @click.stop="handleTagClick('week', tag, v)"
                                >{{ tag.tagName }}{{ tag.isDeleted == 1 ? '(已删除)' : '' }}</span
                              >
                            </a-tooltip>
                          </template>
                          <template v-else>
                            <span @click.stop="handleTagClick('week', tag, v)"
                              >{{ tag.tagName }}{{ tag.isDeleted == 1 ? '(已删除)' : '' }}</span
                            >
                          </template>
                          <a-icon
                            type="close"
                            v-show="v.isCurrent"
                            style="cursor: pointer"
                            @click.stop="handleWeekTagDel(v, val, tag)"
                          />
                        </div>
                        <TopicTreeInput
                          v-if="v.isCurrent"
                          class="tree_input no-drag"
                          :isAccept="true"
                          :acceptList="tagList"
                          show-search
                          style="width: 95%"
                          :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
                          :replaceFields="{ title: 'name' }"
                          placeholder="➕选题"
                          allow-clear
                          tree-default-expand-all
                          @click.stop="() => {}"
                          @input="(select, tagName) => handleWeekTreeSelect(select, v, val, tagName)"
                        />
                      </draggable>
                    </div>
                    <a-menu slot="overlay" @click="(e) => onWeekContextMenuClick(e, v, val, i)">
                      <a-menu-item key="copy"> <a-button type="link">复制</a-button> </a-menu-item>
                      <a-menu-item key="paste"> <a-button type="link">粘贴</a-button> </a-menu-item>
                      <a-menu-item key="delete"> <a-button type="link">删除</a-button> </a-menu-item>
                    </a-menu>
                  </a-dropdown>
                </div>
              </div>
            </a-spin>
          </div>
        </a-tab-pane>

        <!-- 月计划 -->
        <a-tab-pane key="month" tab="月计划">
          <div v-if="currentTab == 'month'">
            <a-space class="search-wrapper">
              <a-input-search
                style="width: 136px"
                placeholder="小红书号/昵称"
                v-model.trim="searchMediaParams.nickname"
                @search="handleSearchMedia"
                allowClear
              ></a-input-search>
              <a-select
                v-model="searchMediaParams.operatorId"
                style="width: 200px"
                placeholder="运营人"
                show-search
                option-filter-prop="children"
                @change="handleSearchMedia"
                allowClear
              >
                <a-select-option v-for="item in allMemberList" :key="item.id" :value="item.id">{{
                  item.operatorName
                }}</a-select-option>
              </a-select>
              <a-select
                style="width: 200px"
                v-model="searchMediaParams.operatorGroupId"
                placeholder="请选择团队"
                show-search
                option-filter-prop="children"
                allowClear
                @change="handleSearchMedia"
              >
                <a-select-option v-for="item in allTeamList" :key="item.id" :value="item.id">{{
                  item.groupName
                }}</a-select-option>
              </a-select>
            </a-space>
            <div class="month-pane">
              <!-- 媒体 -->
              <div class="month-media">
                <a-spin :spinning="loadingSpinning" size="large" tip="加载中...">
                  <div class="month-media-list">
                    <div class="first-col" style="justify-content: space-between; align-items: center">
                      <div>
                        <span style="color: #000">账号</span><span style="margin-left: 10px">{{ mediaTotal }}个</span>
                      </div>
                      <!-- <a-button type="link" icon="user" @click="showAddMedia = true">+账号</a-button> -->
                    </div>
                    <div class="scroll-list">
                      <div
                        v-for="(val, ind) in monthMediaList"
                        :key="ind"
                        :class="['first-col', 'border-col', { 'current-media': val.isCurrent }]"
                        @mouseenter="val.showHover = true"
                        @mouseleave="val.showHover = false"
                      >
                        <div class="media-column" style="cursor: pointer" @click="handleMediaClick(val)">
                          <div class="media-left">
                            <img :src="val.avatar" alt="头像" referrerpolicy="no-referrer" />
                          </div>
                          <div class="media-right">
                            <span style="margin-left: 15px">
                              {{ val.nickname }}
                            </span>
                            <div style="display: flex">
                              <div class="right-txt">
                                <div class="logo">
                                  <img src="@/assets/icon/xhs_logo.png" alt="" />
                                </div>
                                <div v-if="val.authorCode" :title="val.authorCode">
                                  {{
                                    val.authorCode.length > 10 ? `${val.authorCode.slice(0, 10)}...` : val.authorCode
                                  }}
                                </div>
                                <div v-else>-</div>
                              </div>
                              <div class="right-txt">
                                <div class="logo" style="border-radius: 0">
                                  <img v-show="val.photoUrl" :src="val.photoUrl" alt="" referrerpolicy="no-referrer" />
                                </div>
                                <div>{{ personalityTypeObject[val.personalityType] }}</div>
                              </div>
                            </div>
                          </div>
                          <a-icon
                            type="arrows-alt"
                            class="open-icon"
                            v-show="val.showHover"
                            style="right: 25px"
                            @click.stop="openDetailDraw(val)"
                          />
                          <a-popover placement="rightTop">
                            <template slot="content">
                              <a-space direction="vertical">
                                <a-button block @click="handleCopyMonth(val)">复制月计划</a-button>
                                <a-button block @click="handlePasteMonth(val)" :disabled="!monthCopyData.authorId"
                                  >粘贴月计划</a-button
                                >
                                <a-button block @click="handleSaveMonthPlan(val)">保存为模板</a-button>
                                <a-button block @click="handleUsePlanTemplate(val, 'month')">应用计划模板</a-button>
                                <!-- <a-popconfirm
                                  placement="rightTop"
                                  title="移除媒体号?"
                                  ok-text="确认"
                                  cancel-text="取消"
                                  @confirm="handleDelMedia(val.id, 'month')"
                                  @cancel="() => {}"
                                >
                                  <a-button block>移除媒体号</a-button>
                                </a-popconfirm> -->
                              </a-space>
                            </template>
                            <template slot="title"> </template>
                            <a-icon type="more" class="open-icon" />
                          </a-popover>
                        </div>
                      </div>
                    </div>
                  </div>
                </a-spin>
              </div>
              <!-- 日历 -->
              <div class="month-calendar">
                <a-spin :spinning="loadingSpinning" size="large" tip="加载中...">
                  <div class="calendar-top">
                    <div>
                      <a-button type="link" @click="handleBeforeMonth">&lt;</a-button>
                      <a-button type="link" @click="handleThisMonth">本月</a-button>
                      <a-button type="link" @click="handleNextMonth">></a-button>
                    </div>
                  </div>
                  <div class="calendar-bottom">
                    <a-calendar :value="currentMonth" @panelChange="panelChange" @select="monthSelect">
                      <div
                        slot="dateCellRender"
                        slot-scope="value"
                        :class="['events', { weekends: getMonthColumnData(value).isWeekend }]"
                        @click="handleMonthColumnClick(value)"
                      >
                        <a-dropdown
                          key="month"
                          :trigger="['contextmenu']"
                          style="display: flex; flex-direction: column; align-items: center"
                        >
                          <div class="events-item">
                            <draggable
                              style="min-width: 100px; min-height: 33px"
                              :list="getMonthColumnData(value).topicSelectionTagList"
                              :group="{ name: 'month', pull: true, put: true }"
                              filter=".no-drag"
                              @add="(e) => handleMonthDragAdd(e, getMonthColumnData(value), value)"
                              @remove="(e) => handleMonthDragRemove(e, getMonthColumnData(value), value)"
                              @change="(e) => handleMonthDragChange(e, getMonthColumnData(value), value)"
                              @end="handleDragEnd"
                            >
                              <div
                                class="tags"
                                v-for="tag in getMonthColumnData(value).topicSelectionTagList"
                                :key="tag.id"
                                :style="`background-color: ${tag.colorHex}`"
                              >
                                <template v-if="tag.topic">
                                  <a-tooltip>
                                    <template slot="title">
                                      <div style="white-space: break-spaces">{{ tag.topic }}</div>
                                    </template>
                                    <a-icon type="info-circle" style="color: #fff; margin-right: 5px" theme="filled" />
                                    <span @click.stop="handleTagClick('month', tag, value)">
                                      {{ tag.tagName }}{{ tag.isDeleted == 1 ? '(已删除)' : '' }}
                                    </span>
                                  </a-tooltip>
                                </template>
                                <template v-else>
                                  <span @click.stop="handleTagClick('month', tag, value)">
                                    {{ tag.tagName }}{{ tag.isDeleted == 1 ? '(已删除)' : '' }}
                                  </span>
                                </template>
                                <a-icon
                                  v-show="monthDate == getMonthColumnData(value).date"
                                  type="close"
                                  style="cursor: pointer"
                                  @click.stop="handleMonthTagDel(getMonthColumnData(value), tag)"
                                ></a-icon>
                              </div>
                              <template v-if="monthDate == getMonthColumnData(value).date">
                                <TopicTreeInput
                                  :isAccept="true"
                                  :acceptList="tagList"
                                  class="tree_input no-drag"
                                  show-search
                                  style="width: 100%"
                                  :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
                                  :replaceFields="{ title: 'name' }"
                                  placeholder="➕选题"
                                  allow-clear
                                  tree-default-expand-all
                                  @input="(select) => handleMonthTreeSelect(select, getMonthColumnData(value))"
                                  @click.stop="() => {}"
                                />
                              </template>
                            </draggable>
                          </div>
                          <a-menu
                            slot="overlay"
                            @click="
                              (e) => onMonthContextMenuClick(e, value, getMonthColumnData(value).topicSelectionTagList)
                            "
                          >
                            <a-menu-item key="copy"> <a-button type="link">复制</a-button> </a-menu-item>
                            <a-menu-item key="paste"> <a-button type="link">粘贴</a-button> </a-menu-item>
                            <a-menu-item key="delete"> <a-button type="link">删除</a-button> </a-menu-item>
                          </a-menu>
                        </a-dropdown>
                      </div>
                    </a-calendar>
                  </div>
                </a-spin>
              </div>
            </div>
          </div>
        </a-tab-pane>
      </a-tabs>
    </div>

    <!-- 原因弹窗 -->
    <a-modal
      v-model="showReason"
      title="修改原因"
      @cancel="handleEditCancel"
      @ok="handleEditOk"
      :confirmLoading="editLoading"
    >
      <a-textarea placeholder="改动今日之前的计划请说明原因" v-model.trim="editData.description"></a-textarea>
    </a-modal>

    <!-- 添加媒体号弹窗 -->
    <AddMediaComp v-if="showAddMedia" @closeModal="showAddMedia = false" @mediaConfirm="mediaConfirm" />

    <!-- 计划详情 -->
    <PlanDetailDrawer ref="PlanDetailDrawer" @refresh="handleRefresh" />

    <!-- 计划模板 -->
    <PlanTemplateDrawer ref="PlanTemplateDrawer" @useTemplate="handleRefresh" />

    <!-- 选题备注 -->
    <a-modal :visible="showRemark" title="填写主题或要素" :mask-closable="true" @cancel="handleRemarkClose">
      <div style="position: relative">
        <a-textarea
          :auto-size="{ minRows: 2, maxRows: 2 }"
          :maxLength="40"
          v-model.trim="remarkParams.topic"
          allowClear
          @pressEnter="handleSaveRemark"
        ></a-textarea>
        <span style="position: absolute; bottom: -20px; right: 0px; color: #8f8e8e; user-select: none"
          >{{ remarkParams.topic ? remarkParams.topic.length : 0 }}/40</span
        >
      </div>
      <template slot="footer">
        <a-button type="primary" :loading="remarkLoading" @click="handleSaveRemark">确定</a-button>
      </template>
    </a-modal>
  </div>
</template>

<script>
import moment from 'moment';
import { WEEK_RENDER_LIST } from './constant';
import AddMediaComp from './components/AddMediaComp';
import api from '@/api/xhsAgencyApi';
import { personalityTypeList, personalityTypeObject } from './constant';
import TopicTreeInput from '@/components/xhsAgencyTool/TopicTreeInput';
import PlanDetailDrawer from '@/components/xhsAgencyTool/PlanDetailDrawer';
import { downloadExcel } from '@/utils';
import { trackRequest } from '@/utils/track';
import PlanTemplateDrawer from '@/components/xhsAgencyTool/PlanTemplateDrawer.vue';
import draggable from 'vuedraggable';

export default {
  name: 'operatePlan',
  components: { AddMediaComp, TopicTreeInput, PlanDetailDrawer, PlanTemplateDrawer, draggable },
  data() {
    return {
      personalityTypeList,
      personalityTypeObject,
      currentTab: 'week',
      allGroupList: [],
      mediaTotal: 0,
      mediaList: [],
      showReason: false,
      copyData: {},
      editData: {},
      editLoading: false,
      loadingSpinning: false,
      exportLoading: false,
      showAddMedia: false,
      // --------------------------------------------------------------------- 周计划
      currentWeek: moment().week(Number),
      WEEK_RENDER_LIST,
      weekDataList: [],
      todayDate: moment().format('YYYY-MM-DD'),
      currentWeekDetails: {},
      weekCopyData: {
        authorId: null,
      },
      // --------------------------------------------------------------------- 月计划
      monthMediaList: [],
      currentMonth: moment(),
      searchMediaParams: {},
      monthTagList: [],
      monthDate: undefined,
      showMonthSelect: false,
      currentMonthMedia: {},
      monthCopyData: {
        authorId: null,
      },
      // ----埋点
      trackEvent: undefined,
      trackParams: {},
      // ----备注
      showRemark: false,
      editParams: {},
      remarkParams: {
        id: undefined,
        topic: undefined,
      },
      remarkLoading: false,

      tagList: [],
      tagListFlat: [],
      allMemberList: [],
      allTeamList: [],
      moveData: {
        sourcePublishDate: undefined,
        sourceAuthorId: undefined,
        targetPublishDate: undefined,
        targetAuthorId: undefined,
        tagId: undefined,
        afterDragTagList: undefined,
        description: undefined,
      },
    };
  },
  async mounted() {
    await this.getAllTagId();
    this.init();
  },
  methods: {
    // --------------------------------------------------------------------- 共用方法
    async init() {
      this.getAllMemberList();
      this.getAllTeamList();
      this.handleSearchMedia();
    },
    async handleSearchMedia() {
      const params = {
        nickname: this.searchMediaParams.nickname,
        operatorId: this.searchMediaParams.operatorId,
        operatorGroupId: this.searchMediaParams.operatorGroupId,
        status: 'NORMAL',
        page: 1,
        size: 200,
      };
      this.loadingSpinning = true;
      const { code, data, message } = await api.getAgencyList(params).finally(() => (this.loadingSpinning = false));
      if (code == 200) {
        this.mediaList = data.list.map((item) => ({ ...item, authorCode: item.code }));
        this.mediaTotal = data.total;
        if (this.currentMonthMedia.authorId) {
          // 保留月计划当前账号
          const current = data?.list?.find((val) => val.authorId == this.currentMonthMedia.authorId) || undefined;
          if (!current) this.currentMonthMedia = {};
        }
        if (this.currentTab == 'week') {
          this.initWeek();
        }
        if (this.currentTab == 'month') {
          this.initMonth();
        }
      } else {
        this.$message.error(message);
      }
    },
    getAllMemberList() {
      this.handleRequest(
        'getTeamMember',
        (data) => {
          this.allMemberList = data.list;
        },
        { page: 1, size: 999, isDeleted: 0 }
      );
    },
    getAllTeamList() {
      this.handleRequest(
        'getTeamManageList',
        (data) => {
          this.allTeamList = data.list;
        },
        { page: 1, size: 999 }
      );
    },
    mediaConfirm({ code }) {
      this.handleRequest(
        'handleAddPlanMedia',
        () => {
          this.$message.success('添加成功');
          this.handleSearchMedia();
        },
        { authorCodeList: [code] }
      );
    },
    handleTagsChange(value) {
      if (value == 'week') {
        this.initWeek();
      }
      if (value == 'month') {
        this.initMonth();
      }
    },
    async handleEditOk() {
      if (!this.editData.description) return this.$message.error('请填写修改原因');
      this.editLoading = true;
      if (this.moveData.tagId) {
        await this.handleDragTag().finally(() => (this.editLoading = false));
      } else {
        await this.handlePlanEdit(this.editData).finally(() => (this.editLoading = false));
        this.handleTrack();
      }
      this.handleEditCancel();
    },
    handleEditCancel() {
      this.editData = {};
      this.showReason = false;
      this.showMonthSelect = false;
      if (this.moveData.tagId) {
        Object.assign(this.moveData, this.$options.data().moveData);
        if (this.currentTab == 'week') {
          this.initWeek();
        }
        if (this.currentTab == 'month') {
          this.getMediaMonthData();
        }
      }
    },
    async handleJumpXHS(value) {
      const hide = this.$message.loading('查询中...', 0);
      await this.handleRequest(
        'getXhsAccountPage',
        (data) => {
          window.open(data, '_blank');
        },
        {
          authorId: value.authorId,
        }
      ).finally(() => hide());
    },
    async handleRequest(APINAME, callbackFn, PARAMS = null) {
      const { code, data, message } = await api[APINAME](PARAMS);
      if (code === 200) {
        callbackFn(data);
      } else {
        return this.$message.error(`${message}`);
      }
    },
    /**
     * 打开计划详情
     */
    openDetailDraw(mediaData) {
      let params = {};
      if (this.currentTab === 'week') {
        const current = this.handleGetMonthDate(this.searchWeek);
        params = {
          authorId: mediaData.authorId,
          startDate: current.ymdList[0],
          endDate: current.ymdList[current.ymdList.length - 1],
        };
      } else {
        const { startDate, endDate } = this.getSearchMonthRange(this.currentMonth);
        params = {
          authorId: mediaData.authorId,
          startDate,
          endDate,
        };
      }
      this.$refs['PlanDetailDrawer'].openDrawer(params);
    },
    handleShowPlan() {
      this.$refs['PlanTemplateDrawer'].openDrawer('planList');
    },
    handlePlanEdit(params) {
      return new Promise(async (resolve, reject) => {
        const { code, data, message } = await api.handlePlanEdit(params);
        if (code == 200) {
          this.$message.success('修改成功');
          if (this.currentTab == 'week') {
            this.getMediaWeekData();
          }
          if (this.currentTab == 'month') {
            this.getMediaMonthData();
          }
          resolve(data);
        } else {
          this.$message.error(message);
          reject();
        }
      });
    },
    async handleExport() {
      let startDate = '';
      let endDate = '';
      if (this.currentTab == 'week') {
        const current = this.handleGetMonthDate(this.currentWeek);
        startDate = current.ymdList[0];
        endDate = current.ymdList[current.ymdList.length - 1];
      }
      if (this.currentTab == 'month') {
        startDate = this.getSearchMonthRange(this.currentMonth).startDate;
        endDate = this.getSearchMonthRange(this.currentMonth).endDate;
      }
      const fileName = `运营计划 ${startDate}~${endDate}`;
      const params = {
        authorIds: this.mediaList?.map((v) => v.authorId) || [],
        fileName,
        startDate,
        endDate,
      };
      this.exportLoading = true;
      api
        .downloadPlanList(params)
        .then((res) => {
          downloadExcel(res, fileName);
        })
        .finally(() => (this.exportLoading = false));
    },
    async handlePatchPaste(params, callbackFn) {
      const { code, message } = await api.handlePlanBatchPaste(params);
      if (code == 200) {
        this.$message.success('修改成功');
        !!callbackFn && callbackFn();
      } else {
        this.$message.error(message);
      }
    },
    // --------------------------------------------------------------------- 周视图
    async initWeek() {
      // 获取当前周
      const current = this.handleGetMonthDate(this.currentWeek);
      this.currentWeekDetails = current;
      this.WEEK_RENDER_LIST.forEach((v, i) => {
        v.ymdDate = current.ymdList[i];
        v.date = current.dateList[i];
        v.shortDate = current.dateList[i].split('-')[1];
      });
      this.renderMediaWeekList(current.ymdList);
      this.getMediaWeekData();
    },
    async getMediaWeekData() {
      if (!this.mediaList || !this.mediaList.length) {
        this.weekDataList = [];
        return false;
      }
      const current = this.handleGetMonthDate(this.currentWeek);
      const params = {
        authorIds: this.weekDataList.map((val) => val.mediaData.authorId),
        startDate: current.ymdList[0],
        endDate: current.ymdList[current.ymdList.length - 1],
      };
      this.loadingSpinning = true;
      this.handleRequest(
        'getPlanDataList',
        (dataList) => {
          this.weekDataList.forEach((media) => {
            dataList.forEach((data) => {
              if (media.mediaData.authorId == data.authorId) {
                media.weekData = [];
                data.datePlanInfoList.forEach((plan) => {
                  media.weekData.push({
                    isCurrent: false,
                    date: plan.planPublishDate,
                    topicSelectionTagList: plan.topicSelectionTagList,
                  });
                });
              }
            });
          });
        },
        params
      ).finally(() => (this.loadingSpinning = false));
    },
    handleWeekColumnClick(val) {
      const type = val.isCurrent;
      this.weekDataList.forEach((col) => {
        col.weekData.forEach((v) => {
          v.isCurrent = false;
        });
      });
      val.isCurrent = !type;
    },
    handleThisWeek() {
      this.currentWeek = moment().week(Number);
      const current = this.handleGetMonthDate(this.currentWeek);
      this.currentWeekDetails = current;
      this.WEEK_RENDER_LIST.forEach((v, i) => {
        v.ymdDate = current.ymdList[i];
        v.date = current.dateList[i];
        v.shortDate = current.dateList[i].split('-')[1];
      });
      this.handleWeekChange(this.currentWeek);
      this.getMediaWeekData();
    },
    handleBeforeWeek() {
      const week = moment(this.currentWeek).get('week');
      let currentYear = moment(this.currentWeek).get('year');
      let lastYear = moment(this.currentWeek)
        .week(week - 1)
        .get('year');
      if (currentYear > lastYear) {
        const lastYearM = moment(this.currentWeek).subtract(1, 'y');
        this.currentWeek = lastYearM.week(moment(lastYearM).weeksInYear());
      } else {
        this.currentWeek = moment(this.currentWeek).week(week - 1);
      }
      const current = this.handleGetMonthDate(this.currentWeek);
      this.currentWeekDetails = current;
      this.WEEK_RENDER_LIST.forEach((v, i) => {
        v.ymdDate = current.ymdList[i];
        v.date = current.dateList[i];
        v.shortDate = current.dateList[i].split('-')[1];
      });
      this.getMediaWeekData();
    },
    handleNextWeek() {
      const week = moment(this.currentWeek).get('week');
      let currentYear = moment(this.currentWeek).get('year');
      let nextYear = moment(this.currentWeek)
        .week(week + 1)
        .get('year');
      if (nextYear > currentYear) {
        this.currentWeek = moment(this.currentWeek).add(1, 'y').week(1);
      } else {
        this.currentWeek = moment(this.currentWeek).week(week + 1);
      }
      const current = this.handleGetMonthDate(this.currentWeek);
      this.currentWeekDetails = current;
      this.WEEK_RENDER_LIST.forEach((v, i) => {
        v.ymdDate = current.ymdList[i];
        v.date = current.dateList[i];
        v.shortDate = current.dateList[i].split('-')[1];
      });
      this.getMediaWeekData();
    },
    handleGetMonthDate(data) {
      const year = moment(data).get('year');
      const week = moment(data).get('week');
      let date = {};
      date.startDate = moment(`${year}-${week}`, 'GGGG-WW').format('MM-DD');
      date.startYear = `${moment(`${year}-${week}`, 'GGGG-WW').format('YYYY')}年`;
      // ${moment(`${year}-${week}`,'GGGG-WW').format('MM')}月
      date.endDate = moment(`${year}-${week}`, 'GGGG-WW').weekday(6).format('MM-DD');
      date.dateList = [];
      date.ymdList = [];
      for (let i = 0; i < 7; i++) {
        const d = moment(`${year}-${week}`, 'GGGG-WW').weekday(i).format('MM-DD');
        const ymd = moment(`${year}-${week}`, 'GGGG-WW').weekday(i).format('YYYY-MM-DD');
        date.dateList.push(d);
        date.ymdList.push(ymd);
      }
      return date;
    },
    renderMediaWeekList(dateList) {
      const rtList = [];
      if (this.mediaList?.length) {
        this.mediaList.forEach((media) => {
          const listItem = {
            mediaData: media,
            showHover: false,
            weekData: [],
          };
          dateList.forEach((date) => {
            listItem.weekData.push({
              date,
              isCurrent: false,
              // showSelect: false,
              topicSelectionTagList: [],
            });
          });
          rtList.push(listItem);
        });
      }
      this.weekDataList = rtList;
    },
    handleWeekChange(date) {
      this.currentWeek = date;
      const current = this.handleGetMonthDate(date);
      this.currentWeekDetails = current;
      this.WEEK_RENDER_LIST.forEach((v, i) => {
        v.ymdDate = current.ymdList[i];
        v.date = current.dateList[i];
        v.shortDate = current.dateList[i].split('-')[1];
      });
      this.getMediaWeekData();
    },
    handleWeekTreeSelect(select, week, data, tagName) {
      if (select.length) {
        this.loadingSpinning = true;
        // week.showSelect = false;
        const nowDate = moment();
        const selectTime = moment(`${week.date}T23:59:59`);

        this.trackEvent = 'add_plan';
        this.trackParams = {
          plan_time: week.date,
          media_platform: '小红书',
          media_code: data.mediaData.authorCode,
          media_neckname: data.mediaData.nickname,
          media_id: data.mediaData.authorId,
          media_member: undefined,
          media_brand: undefined,
          topic: tagName,
        };

        if (selectTime.isBefore(nowDate)) {
          this.loadingSpinning = false;
          this.editData = {
            authorId: data.mediaData.authorId,
            avatar: data.mediaData.avatar,
            nickname: data.mediaData.nickname,
            planPublishDate: week.date,
            topicSelectionTagIdList: [...week.topicSelectionTagList.map((tag) => tag.id), select],
          };
          this.showReason = true;
        } else {
          this.handlePlanEdit({
            authorId: data.mediaData.authorId,
            avatar: data.mediaData.avatar,
            nickname: data.mediaData.nickname,
            planPublishDate: week.date,
            topicSelectionTagIdList: [...week.topicSelectionTagList.map((tag) => tag.id), select],
          }).then((data) => {
            if (data.newIdList?.length) {
              this.remarkParams.id = data.newIdList[0];
            }
          });
          this.handleTrack();
        }
      }
    },
    onWeekContextMenuClick({ key: eventState }, week, data, index) {
      if (eventState == 'copy') {
        this.copyData = {
          copyId: data.mediaData.authorId,
          topicSelectionTagList: week.topicSelectionTagList,
        };
        this.$message.success('复制成功');

        this.trackEvent = 'copy_plan';
        this.trackParams = {
          plan_time: week.date,
          media_platform: '小红书',
          media_code: data.mediaData.authorCode,
          media_neckname: data.mediaData.nickname,
          media_id: data.mediaData.authorId,
          media_member: undefined,
          media_brand: undefined,
          plan_view: '周计划',
        };
        this.handleTrack();
      }
      if (eventState == 'paste' || eventState == 'delete') {
        if (eventState == 'paste' && !this.copyData.copyId) return this.$message.info('没有可粘贴数据');
        this.trackEvent = `${eventState}_plan`;
        this.trackParams = {
          media_platform: '小红书',
          media_code: data.mediaData.authorCode,
          media_neckname: data.mediaData.nickname,
          media_id: data.mediaData.authorId,
          media_member: undefined,
          media_brand: undefined,
        };
        if (eventState == 'delete') {
          this.trackParams.plan_time = week.date;
          this.trackParams.topic = data['weekData'][index]['topicSelectionTagList'].map((v) => v.tagName);
        }

        if (eventState == 'paste') this.trackParams.plan_view = '周计划';
        const nowDate = moment();
        const selectTime = moment(`${week.date}T23:59:59`);
        const pasteList = this.copyData?.topicSelectionTagList?.length
          ? this.copyData.topicSelectionTagList.map((tag) => tag.id)
          : [];
        if (selectTime.isBefore(nowDate)) {
          this.editData = {
            authorId: data.mediaData.authorId,
            avatar: data.mediaData.avatar,
            nickname: data.mediaData.nickname,
            planPublishDate: week.date,
            topicSelectionTagIdList: eventState == 'paste' ? pasteList : [],
          };
          this.showReason = true;
          return false;
        }
        this.handlePlanEdit({
          authorId: data.mediaData.authorId,
          avatar: data.mediaData.avatar,
          nickname: data.mediaData.nickname,
          planPublishDate: week.date,
          topicSelectionTagIdList: eventState == 'paste' ? pasteList : [],
        });
        this.handleTrack();
      }
    },
    handleWeekTagDel(week, data, tag) {
      this.trackEvent = 'delete_plan';
      this.trackParams = {
        plan_time: week.date,
        media_platform: '小红书',
        media_code: data.mediaData.authorCode,
        media_neckname: data.mediaData.nickname,
        media_id: data.mediaData.authorId,
        media_member: undefined,
        media_brand: undefined,
        topic: tag.tagName,
      };

      const nowDate = moment();
      const selectTime = moment(`${week.date}T23:59:59`);
      const topicSelectionTagIdList = JSON.parse(JSON.stringify(week.topicSelectionTagList));
      this.loadingSpinning = true;
      const dIndex = week.topicSelectionTagList.map((val) => val.id).indexOf(tag.id);
      topicSelectionTagIdList.splice(dIndex, 1);
      if (selectTime.isBefore(nowDate)) {
        this.loadingSpinning = false;
        this.editData = {
          authorId: data.mediaData.authorId,
          avatar: data.mediaData.avatar,
          nickname: data.mediaData.nickname,
          planPublishDate: week.date,
          topicSelectionTagIdList: topicSelectionTagIdList.length ? topicSelectionTagIdList.map((val) => val.id) : [],
        };
        this.showReason = true;
        return false;
      }
      this.handlePlanEdit({
        authorId: data.mediaData.authorId,
        avatar: data.mediaData.avatar,
        nickname: data.mediaData.nickname,
        planPublishDate: week.date,
        topicSelectionTagIdList: topicSelectionTagIdList.length ? topicSelectionTagIdList.map((val) => val.id) : [],
      });
      this.handleTrack();
    },
    handleCopyWeek(data) {
      this.weekCopyData = {};
      this.$message.success('已复制周计划');
      this.weekCopyData = {
        authorId: data.mediaData.authorId,
        startDate: this.currentWeekDetails.ymdList[0],
        endDate: this.currentWeekDetails.ymdList[6],
      };

      this.trackEvent = 'copy_plan';
      this.trackParams = {
        media_platform: '小红书',
        media_code: data.mediaData.authorCode,
        media_neckname: data.mediaData.nickname,
        media_id: data.mediaData.authorId,
        media_member: undefined,
        media_brand: undefined,
        plan_view: '周计划',
      };
      this.handleTrack();
    },
    handlePasteWeek(data) {
      if (!this.weekCopyData.authorId) return this.$message.info('没有可粘贴周数据');
      const pasteParams = {
        source: {
          authorId: this.weekCopyData.authorId,
          startDate: this.weekCopyData.startDate,
          endDate: this.weekCopyData.endDate,
        },
        target: {
          authorId: data.mediaData.authorId,
          startDate: this.currentWeekDetails.ymdList[0],
          endDate: this.currentWeekDetails.ymdList[6],
        },
      };
      let hasSource = false;
      data.weekData.forEach((week) => {
        if (week.topicSelectionTagList.length) hasSource = true;
      });

      this.trackEvent = 'paste_plan';
      this.trackParams = {
        media_platform: '小红书',
        media_code: data.mediaData.authorCode,
        media_neckname: data.mediaData.nickname,
        media_id: data.mediaData.authorId,
        media_member: undefined,
        media_brand: undefined,
        plan_view: '周计划',
      };
      this.handleTrack();
      if (hasSource) {
        this.showMonthPasteConfirm(pasteParams, this.getMediaWeekData);
      } else {
        this.handlePatchPaste(pasteParams, this.getMediaWeekData);
      }
    },
    async handleDelMedia(id, type) {
      const { code, message } = await api.handlePlanMediaDel(id);
      if (code == 200) {
        this.$message.success('删除成功');
        await this.handleSearchMedia();
        if (type == 'week') {
          this.initWeek();
        } else {
          this.initMonth();
        }
      } else {
        this.$message.error(message);
      }
    },
    // --------------------------------------------------------------------- 周视图结束
    // --------------------------------------------------------------------- 月视图
    initMonth() {
      this.renderMediaMonthList();
      this.getMediaMonthData();
    },
    renderMediaMonthList() {
      this.monthMediaList =
        this.mediaList?.map((media, index) => ({
          ...media,
          showHover: false,
          isCurrent: index == 0 ? true : false,
        })) || [];
    },
    getMediaMonthData() {
      this.showMonthSelect = false;
      this.monthDate = undefined;
      if (!this.mediaList || !this.mediaList.length) {
        this.currentMonthMedia = {};
        this.monthMediaList = [];
        this.monthTagList = [];
        return false;
      }
      const currentMedia = this.monthMediaList.find((val) => val.isCurrent);
      if (currentMedia && currentMedia.authorId) {
        this.currentMonthMedia = currentMedia;
        const { startDate, endDate } = this.getSearchMonthRange(this.currentMonth);
        const params = {
          authorIds: [currentMedia.authorId],
          startDate,
          endDate,
        };
        this.loadingSpinning = true;
        this.handleRequest(
          'getPlanDataList',
          (dataList) => {
            this.monthTagList = dataList[0].datePlanInfoList;
          },
          params
        ).finally(() => (this.loadingSpinning = false));
      } else {
        this.currentMonthMedia = {};
      }
    },
    handleMonthColumnClick(column) {
      if (moment(this.currentMonth).format('YYYY-MM-DD') == moment(column).format('YYYY-MM-DD') && this.monthDate) {
        // 反选
        this.currentMonth = column;
        this.monthDate = undefined;
        this.showMonthSelect = false;
        return;
      }
      this.monthDate = moment(column).format('YYYY-MM-DD');
      this.showMonthSelect = false;
    },
    handleMonthTreeSelect(select, month) {
      if (!this.currentMonthMedia.authorId) return this.$message.info('请先选择账号');
      if (select.length) {
        this.loadingSpinning = true;
        const nowDate = moment();
        const selectTime = moment(`${month.date}T23:59:59`);
        if (selectTime.isBefore(nowDate)) {
          this.loadingSpinning = false;
          this.editData = {
            authorId: this.currentMonthMedia.authorId,
            avatar: this.currentMonthMedia.avatar,
            nickname: this.currentMonthMedia.nickname,
            planPublishDate: month.date,
            topicSelectionTagIdList: [...month.topicSelectionTagList.map((tag) => tag.id), select],
          };
          this.showReason = true;
        } else {
          this.handlePlanEdit({
            authorId: this.currentMonthMedia.authorId,
            avatar: this.currentMonthMedia.avatar,
            nickname: this.currentMonthMedia.nickname,
            planPublishDate: month.date,
            topicSelectionTagIdList: [...month.topicSelectionTagList.map((tag) => tag.id), select],
          }).then((data) => {
            if (data.newIdList?.length) {
              this.remarkParams.id = data.newIdList[0];
            }
          });
        }
      }
    },
    handleMonthTagDel(month, { id, tagName }) {
      if (!this.currentMonthMedia.authorId) return this.$message.info('请先选择账号');

      this.trackEvent = 'delete_plan';
      this.trackParams = {
        plan_time: month.date,
        media_platform: '小红书',
        media_code: this.currentMonthMedia.authorCode,
        media_neckname: this.currentMonthMedia.nickname,
        media_id: this.currentMonthMedia.authorId,
        media_member: undefined,
        media_brand: undefined,
        topic: tagName,
      };

      const nowDate = moment();
      const selectTime = moment(`${month.date}T23:59:59`);
      const topicSelectionTagIdList = JSON.parse(JSON.stringify(month.topicSelectionTagList));
      this.loadingSpinning = true;
      const dIndex = month.topicSelectionTagList.map((val) => val.id).indexOf(id);
      topicSelectionTagIdList.splice(dIndex, 1);
      if (selectTime.isBefore(nowDate)) {
        this.loadingSpinning = false;
        this.editData = {
          authorId: this.currentMonthMedia.authorId,
          avatar: this.currentMonthMedia.avatar,
          nickname: this.currentMonthMedia.nickname,
          planPublishDate: month.date,
          topicSelectionTagIdList: topicSelectionTagIdList.length ? topicSelectionTagIdList.map((val) => val.id) : [],
        };
        this.showReason = true;
        return false;
      }
      this.handlePlanEdit({
        authorId: this.currentMonthMedia.authorId,
        avatar: this.currentMonthMedia.avatar,
        nickname: this.currentMonthMedia.nickname,
        planPublishDate: month.date,
        topicSelectionTagIdList: topicSelectionTagIdList.length ? topicSelectionTagIdList.map((val) => val.id) : [],
      });
      this.handleTrack();
    },
    getMonthColumnData(column) {
      const date = moment(column).format('YYYY-MM-DD');
      const findData = this.monthTagList.find((val) => val.planPublishDate == date);
      let topicSelectionTagList = [];
      if (findData && findData.topicSelectionTagList.length) {
        topicSelectionTagList = findData.topicSelectionTagList;
      }
      const currentDayOfWeek = moment(column).day();
      const returnData = {
        date,
        topicSelectionTagList,
        isWeekend: currentDayOfWeek === 0 || currentDayOfWeek === 6 ? true : false,
      };
      return returnData;
    },
    panelChange(current) {
      this.currentMonth = current;
      this.monthDate = moment(this.currentMonth).format('YYYY-MM-DD');
      this.getMediaMonthData();
    },
    monthSelect(m) {
      this.currentMonth = m;
    },
    onMonthContextMenuClick({ key: eventState }, date, tagList) {
      if (!this.currentMonthMedia.authorId) return this.$message.info('请先选择账号');

      const { startDate, endDate } = this.getSearchMonthRange(this.currentMonth);
      const start = moment(`${startDate}T00:00:00`);
      const end = moment(`${endDate}T23:59:59`);
      const isWithinRange = date.isBetween(start, end, undefined, '[]');
      if (!isWithinRange) return this.$message.info('请操作当前月份数据');

      const current = this.getMonthColumnData(date);
      if (eventState == 'copy') {
        this.copyData = {
          copyId: this.currentMonthMedia.authorId,
          topicSelectionTagList: current.topicSelectionTagList,
        };
        this.$message.success('复制成功');
        this.trackEvent = 'copy_plan';
        this.trackParams = {
          plan_time: week.date,
          media_platform: '小红书',
          media_code: this.currentMonthMedia.authorCode,
          media_neckname: this.currentMonthMedia.nickname,
          media_id: this.currentMonthMedia.authorId,
          media_member: undefined,
          media_brand: undefined,
          plan_view: '月计划',
        };
        this.handleTrack();
      }
      if (eventState == 'paste' || eventState == 'delete') {
        if (eventState == 'paste' && !this.copyData.copyId) return this.$message.info('没有可粘贴数据');

        this.trackEvent = `${eventState}_plan`;
        this.trackParams = {
          media_platform: '小红书',
          media_code: this.currentMonthMedia.authorCode,
          media_neckname: this.currentMonthMedia.nickname,
          media_id: this.currentMonthMedia.authorId,
          media_member: undefined,
          media_brand: undefined,
        };
        if (eventState == 'delete') {
          this.trackParams.plan_time = date;
          this.trackParams.topic = tagList.map((v) => v.tagName);
        }

        if (eventState == 'paste') this.trackParams.plan_view = '月计划';

        const nowDate = moment();
        const selectTime = moment(`${current.date}T23:59:59`);
        const pasteList = this.copyData?.topicSelectionTagList?.length
          ? this.copyData.topicSelectionTagList.map((tag) => tag.id)
          : [];
        if (selectTime.isBefore(nowDate)) {
          this.editData = {
            authorId: this.currentMonthMedia.authorId,
            avatar: this.currentMonthMedia.avatar,
            nickname: this.currentMonthMedia.nickname,
            planPublishDate: current.date,
            topicSelectionTagIdList: eventState == 'paste' ? pasteList : [],
          };
          this.showReason = true;
          return false;
        }
        this.handlePlanEdit({
          authorId: this.currentMonthMedia.authorId,
          avatar: this.currentMonthMedia.avatar,
          nickname: this.currentMonthMedia.nickname,
          planPublishDate: current.date,
          topicSelectionTagIdList: eventState == 'paste' ? pasteList : [],
        });
        this.handleTrack();
      }
    },
    handleMediaClick(val) {
      if (val.isCurrent == true) return false;
      this.monthMediaList.forEach((v) => (v.isCurrent = false));
      val.isCurrent = true;
      this.getMediaMonthData();
    },
    handleThisMonth() {
      this.currentMonth = moment();
      this.monthDate = moment(this.currentMonth).format('YYYY-MM-DD');
      this.getMediaMonthData();
    },
    handleBeforeMonth() {
      this.currentMonth = moment(this.currentMonth).subtract(1, 'month');
      this.monthDate = moment(this.currentMonth).format('YYYY-MM-DD');

      this.getMediaMonthData();
    },
    handleNextMonth() {
      this.currentMonth = moment(this.currentMonth).add(1, 'month');
      this.monthDate = moment(this.currentMonth).format('YYYY-MM-DD');

      this.getMediaMonthData();
    },
    getSearchMonthRange(data) {
      const startDate = moment(data).date(1).startOf('month').format('YYYY-MM-DD');
      const endDate = moment(data).date(1).endOf('month').format('YYYY-MM-DD');
      const date = {
        startDate,
        endDate,
      };
      return date;
    },
    handleCopyMonth(data) {
      this.monthCopyData = {};
      this.$message.success('已复制月计划');
      const { startDate, endDate } = this.getSearchMonthRange(this.currentMonth);
      this.monthCopyData = {
        authorId: data.authorId,
        startDate,
        endDate,
      };

      this.trackEvent = 'copy_plan';
      this.trackParams = {
        media_platform: '小红书',
        media_code: data.authorCode,
        media_neckname: data.nickname,
        media_id: data.authorId,
        media_member: undefined,
        media_brand: undefined,
        plan_view: '月计划',
      };
      this.handleTrack();
    },
    async handlePasteMonth(data) {
      if (!this.monthCopyData.authorId) return this.$message.info('没有可粘贴月数据');

      this.trackEvent = 'paste_plan';
      this.trackParams = {
        media_platform: '小红书',
        media_code: data.authorCode,
        media_neckname: data.nickname,
        media_id: data.authorId,
        media_member: undefined,
        media_brand: undefined,
        plan_view: '月计划',
      };
      this.handleTrack();

      const { startDate, endDate } = this.getSearchMonthRange(this.currentMonth);
      const pasteParams = {
        source: {
          authorId: this.monthCopyData.authorId,
          startDate: this.monthCopyData.startDate,
          endDate: this.monthCopyData.endDate,
        },
        target: {
          authorId: data.authorId,
          startDate,
          endDate,
        },
      };

      if (data.isCurrent == true) {
        // 当前账号粘贴
        let hasSource = false;
        this.monthTagList.forEach((tag) => {
          if (tag.topicSelectionTagList.length > 0) hasSource = true;
        });
        if (hasSource) {
          this.showMonthPasteConfirm(pasteParams, this.getMediaMonthData);
        } else {
          this.handlePatchPaste(pasteParams, this.getMediaMonthData);
        }
        return;
      }

      // 非当前账号粘贴
      this.monthMediaList.forEach((v) => (v.isCurrent = false));
      data.isCurrent = true;
      this.showMonthSelect = false;
      this.monthDate = undefined;
      if (!this.mediaList || !this.mediaList.length) {
        this.currentMonthMedia = {};
        this.monthMediaList = [];
        this.monthTagList = [];
        return false;
      }
      const currentMedia = this.monthMediaList.find((val) => val.isCurrent);
      if (currentMedia && currentMedia.authorId) {
        this.currentMonthMedia = currentMedia;
        const { startDate, endDate } = this.getSearchMonthRange(this.currentMonth);
        const params = {
          authorIds: [currentMedia.authorId],
          startDate,
          endDate,
        };
        this.loadingSpinning = true;
        this.handleRequest(
          'getPlanDataList',
          (dataList) => {
            this.monthTagList = dataList[0].datePlanInfoList;
            let hasSource = false;
            this.monthTagList.forEach((tag) => {
              if (tag.topicSelectionTagList.length > 0) hasSource = true;
            });
            if (hasSource) {
              this.showMonthPasteConfirm(pasteParams, this.getMediaMonthData);
            } else {
              this.handlePatchPaste(pasteParams, this.getMediaMonthData);
            }
          },
          params
        ).finally(() => (this.loadingSpinning = false));
      } else {
        this.currentMonthMedia = {};
      }
    },
    showMonthPasteConfirm(params, callbackFn = null) {
      const that = this;
      this.$confirm({
        title: '确认粘贴数据?',
        content: (h) => <div>原数据将会被覆盖</div>,
        async onOk() {
          that.handlePatchPaste(params, callbackFn);
        },
        zIndex: 99999,
      });
    },
    // --------------------------------------------------------------------- 月视图结束
    // 埋点
    handleTrack() {
      const to = this.$route;
      trackRequest(to, this.trackEvent, this.trackParams);
    },
    // --------------------------------------------------------------------- 计划模板相关
    handleSaveMonthPlan(val) {
      const params = {
        makeType: 'MONTH',
        authorId: val.authorId,
        startDate: `${moment(this.currentMonth).format('YYYY-MM')}-01`,
      };
      this.$refs['PlanTemplateDrawer'].saveMonthPlan(params);
    },
    handleSavePlanTemplate(val) {
      this.$refs['PlanTemplateDrawer'].openDrawer('planUpdate', val);
    },
    handleUsePlanTemplate(val, type) {
      const extraData = {};
      if (type === 'week') {
        extraData.authorId = val.mediaData.authorId;
        extraData.startDate = moment(val.weekData[0].date);
        extraData.mediaData = {
          avatar: val.mediaData.avatar,
          nickname: val.mediaData.nickname,
          authorCode: val.mediaData.authorCode,
        };
      } else {
        const fDate = `${moment(this.currentMonth).format('YYYY-MM')}-01`;
        extraData.authorId = val.authorId;
        extraData.startDate = moment(fDate);
        extraData.mediaData = {
          avatar: val.avatar,
          nickname: val.nickname,
          authorCode: val.authorCode,
        };
      }
      this.$refs['PlanTemplateDrawer'].openDrawer('planUse', extraData);
    },
    handleTagClick(type, { objectId, topic }, value) {
      if (type === 'month') {
        if (this.monthDate == this.getMonthColumnData(value).date) {
          this.remarkParams.id = objectId;
          if (topic) this.remarkParams.topic = topic;
        } else {
          this.handleMonthColumnClick(value);
        }
      } else {
        if (value.isCurrent) {
          this.remarkParams.id = objectId;
          if (topic) this.remarkParams.topic = topic;
        } else {
          this.handleWeekColumnClick(value);
        }
      }
    },
    async handleSaveRemark() {
      this.remarkLoading = true;
      const params = {
        ...this.remarkParams,
      };
      if (!params.topic) params.params = '';
      const { code, message } = await api.addXhsPromotionInfoRemark(params).finally(() => {
        this.remarkLoading = false;
      });
      if (code === 200) {
        this.handleRefresh();
        this.$message.success('修改成功');
        this.handleRemarkClose();
      } else {
        this.$message.error(message);
      }
    },
    handleRemarkClose() {
      this.remarkLoading = false;
      this.showRemark = false;
      this.remarkParams = this.$options.data().remarkParams;
    },
    handleRefresh() {
      if (this.currentTab == 'week') {
        this.initWeek();
      }
      if (this.currentTab == 'month') {
        this.getMediaMonthData();
      }
    },
    // 获取全部选题
    async getAllTagId() {
      const { code, data, message } = await api.getTopicTagTree(0);
      if (code === 200) {
        this.tagList = data.map(this.initTagValue);
        this.tagListFlat = this.flattenTree(this.tagList);
      } else {
        return this.$message.error(`${message}`);
      }
    },
    // 选题初始化
    initTagValue(data) {
      if (data.children && data.children.length > 0) {
        data.children = data.children.map(this.initTagValue);
      }
      return {
        ...data,
        name: data.tagName,
        value: data.id,
        key: data.id,
        disabled: data.id === this.originId,
      };
    },
    // 选题降维
    flattenTree(tree) {
      const result = [];
      function traverse(node) {
        result.push(node);
        if (node.children) {
          for (const child of node.children) {
            traverse(child);
          }
        }
      }
      for (const item of tree) {
        traverse(item);
      }

      return result;
    },
    // ---------------------------------------------------选题拖拽
    handleWeekDragAdd(evt, v, val) {
      this.moveData.targetPublishDate = v.date;
      this.moveData.targetAuthorId = val.mediaData?.authorId;
      this.moveData.afterDragTagList = v.topicSelectionTagList?.length ? v.topicSelectionTagList : undefined;
    },
    handleWeekDragRemove(evt, v, val) {
      this.moveData.sourcePublishDate = v.date;
      this.moveData.sourceAuthorId = val.mediaData?.authorId;
    },
    handleWeekDragChange(evt) {
      if (evt.removed) {
        this.moveData.tagId = evt.removed.element.id;
      }
    },
    handleMonthDragAdd(e, v) {
      this.moveData.targetPublishDate = v.date;
      this.moveData.afterDragTagList = v.topicSelectionTagList?.length ? v.topicSelectionTagList : undefined;
    },
    handleMonthDragRemove(e, v) {
      this.moveData.sourcePublishDate = v.date;
    },
    handleMonthDragChange(evt) {
      if (evt.removed) {
        this.moveData.tagId = evt.removed.element.id;
        const currentMedia = this.monthMediaList.find((val) => val.isCurrent);
        this.moveData.sourceAuthorId = currentMedia.authorId;
        this.moveData.targetAuthorId = currentMedia.authorId;
      }
    },
    async handleDragEnd() {
      if (
        this.moveData.targetPublishDate == this.moveData.sourcePublishDate &&
        this.moveData.targetAuthorId == this.moveData.sourceAuthorId
      ) {
        Object.assign(this.moveData, this.$options.data().moveData);
        return;
      }
      if (this.moveData.afterDragTagList?.length) {
        const uniqueSampleTypeIds = new Set(this.moveData.afterDragTagList.map((item) => item.id));
        const hasDuplicateSampleTypeId = this.moveData.afterDragTagList.length !== uniqueSampleTypeIds.size;
        if (hasDuplicateSampleTypeId) {
          this.$message.error('目标日期已存在该选题');
          if (this.currentTab == 'week') {
            this.initWeek();
          }
          if (this.currentTab == 'month') {
            this.getMediaMonthData();
          }
          Object.assign(this.moveData, this.$options.data().moveData);
          return;
        }
      }
      const nowDate = moment();
      const afterDate = moment(`${this.moveData.targetPublishDate}T23:59:59`);
      if (afterDate.isBefore(nowDate)) {
        this.showReason = true;
      } else {
        await this.handleDragTag().finally(() => {
          Object.assign(this.moveData, this.$options.data().moveData);
          if (this.currentTab == 'week') {
            this.initWeek();
          }
          if (this.currentTab == 'month') {
            this.getMediaMonthData();
          }
        });
      }
    },
    handleDragTag() {
      return new Promise(async (resolve, reject) => {
        const { code, message } = await api.handleDragTag({
          ...this.moveData,
          description: this.editData.description || undefined,
        });
        if (code == 200) {
          this.$message.success('操作成功');
          resolve();
        } else {
          this.$message.error(message);
          reject();
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.events {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
}

.events-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
}

// .weekends {
// background-color: #f6f6f6 !important;
// }

/deep/.ant-fullcalendar-content {
  &::-webkit-scrollbar {
    width: 6px;
    height: 10px;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-track {
    background: #fff;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background: #00152984;

    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #0015294c;
  }

  &::-webkit-scrollbar-corner {
    background: #fff;
  }
}
.notes-month {
  text-align: center;
  font-size: 28px;
}
.notes-month section {
  font-size: 28px;
}

.first-col {
  flex: 2;
  display: flex;
  padding: 5px;
  min-width: 260px;
}

.border-col {
  border: 1px solid #eaeaea85;
}

.week-row {
  display: flex;
  position: relative;
  .gray-col {
    background-color: #f6f6f6;
  }

  .current-col {
    background-color: #3da2ff2e;
  }

  .today-head-dot {
    position: absolute;
    bottom: 0;
    width: 7px;
    height: 7px;
    border-radius: 7px;
    background-color: #1890ff;
  }

  .today-col {
    position: absolute;
    transform: translateX(1px);
    width: 1px;
    height: 100%;
    background-color: #1890ff;
  }

  .week-col {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px;
    min-width: 100px;
    color: #000;
    cursor: pointer;
  }
}

.media-column {
  position: relative;
  display: flex;
  width: 100%;
  margin-top: 10px;
  text-align: left;
  user-select: none;

  .media-left {
    width: 50px;
    height: 50px;
    border-radius: 25px;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
    }
  }
  .avatar-left {
    width: 50px;
    height: 50px;
    margin-right: 15px;
    overflow: hidden;
    cursor: pointer;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .media-right {
    flex: 1;

    .right-txt {
      display: flex;
      justify-content: start;
      align-items: center;

      .logo {
        width: 20px;
        height: 20px;
        border-radius: 10px;
        overflow: hidden;

        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
  .avatar-right {
    flex: 1;
    display: flex;
    align-items: center;
  }

  .line-flex {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

.search-wrapper {
  position: absolute;
  z-index: 999;
}

.tags {
  min-width: 100px;
  margin-bottom: 5px;
  padding: 5px;
  border-radius: 5px;
  min-width: 70px;
  color: #fff;
  background: #adafb3;
  user-select: none;
  font-size: 12px;
  text-align: center;
  z-index: 2;
  word-break: break-all;
}

.open-icon {
  position: absolute;
  top: 0;
  right: 0;
  font-size: 18px;
  color: #1890ff;
  cursor: pointer;
}

.month-pane {
  display: flex;
  .month-media {
    width: 280px;
    padding-top: 32px;

    .month-media-list {
      .scroll-list {
        max-height: 83vh;
        overflow-y: auto;

        &::-webkit-scrollbar {
          width: 10px;
          height: 10px;
          border-radius: 10px;
        }

        &::-webkit-scrollbar-track {
          background: #fff;
          border-radius: 10px;
        }

        &::-webkit-scrollbar-thumb {
          background: #00152984;

          border-radius: 10px;
        }

        &::-webkit-scrollbar-thumb:hover {
          background: #0015294c;
        }

        &::-webkit-scrollbar-corner {
          background: #fff;
        }
      }
    }
  }
  .month-calendar {
    flex: 1;
    position: relative;

    .calendar-top {
      position: absolute;
      top: 10px;
      right: 210px;
    }

    .calendar-bottom {
      /deep/ .ant-radio-group {
        display: none;
      }
    }
  }

  .current-media {
    background-color: #e6f7ff;
  }
}

.author_code_item {
  max-width: 80px;
  white-space: nowrap;
  word-break: break-all;
  overflow: hidden;
  text-overflow: ellipsis;
}

.tree_input {
  ::v-deep .ant-select-selection__placeholder {
    color: #000000a8;
  }
}
</style>
