<template>
  <a-drawer :visible="visible" width="800" title="计划详情" @close="handleClose">
    <a-tabs v-model="currentTab" @change="handleTabChange">
      <a-tab-pane key="1" tab="计划详情"> </a-tab-pane>
      <a-tab-pane key="2" tab="历史记录"> </a-tab-pane>
    </a-tabs>

    <template v-if="currentTab === '1'">
      <a-spin :spinning="loading">
        <a-row :gutter="10">
          <a-col :span="12">
            <div class="media-column">
              <div class="media-left" v-show="detailInfo.avatar">
                <img :src="detailInfo.avatar" alt="头像" referrerpolicy="no-referrer" />
              </div>
              <div class="media-right">
                <div style="margin-left: 15px" class="blue-text">{{ detailInfo.nickname }}</div>
                <div class="right-txt">
                  <div class="logo" v-show="detailInfo.authorCode">
                    <img src="@/assets/icon/xhs_logo.png" alt="" />
                  </div>
                  <div>{{ detailInfo.authorCode || '-' }}</div>
                </div>
              </div>
            </div>
          </a-col>
          <a-col :span="12">
            <div class="media-column">
              <div class="avatar-left" v-show="detailInfo.photoUrl">
                <img :src="detailInfo.photoUrl" alt="头像" referrerpolicy="no-referrer" />
              </div>
              <div class="avatar-right">
                <div>{{ personalityTypeObject[detailInfo.personalityType] }}</div>
              </div>
            </div>
          </a-col>
        </a-row>
        <div class="title-wrapper">
          <span> 计划 </span>
          <a-dropdown
            overlayClassName="plan-detail-dropdown"
            v-model="showDropdown"
            :trigger="['click']"
            @visibleChange="
              (value) => {
                openTimePanel = value;
              }
            "
          >
            <a @click="(e) => e.preventDefault()">{{ searchTimeRangeText }} <a-icon type="down" /></a>
            <a-menu slot="overlay">
              <a-menu-item>
                <a-range-picker
                  :value="checkTimeRange"
                  :ranges="timeRange"
                  :open="openTimePanel"
                  :disabled-date="disabledPriceRangeDate"
                  @change="onTimeChange"
                  @calendarChange="calendarPriceRangeChange"
                />
              </a-menu-item>
            </a-menu>
          </a-dropdown>
        </div>

        <div class="count-list">
          <a-tag
            style="margin-bottom: 4px"
            v-for="(item, index) in detailInfo.statInfoList"
            :key="index"
            :color="item.colorHex"
            >{{ item.tagName }} {{ item.count }}</a-tag
          >
        </div>

        <a-timeline>
          <a-timeline-item
            v-for="(item, index) in detailInfo.datePlanInfoList"
            :key="index"
            :color="today === item.planPublishDate ? 'blue' : 'gray'"
          >
            <div class="time-line">
              <span class="time">
                {{ item.planPublishDate }}
              </span>
              <div>
                <div v-for="(tag, tIndex) in item.topicSelectionTagList" :key="tIndex">
                  <a-tag style="margin-bottom: 4px; cursor: pointer" :color="tag.colorHex" @click="handleEdit(tag)">
                    <a-tooltip>
                      <template slot="title">
                        <div style="word-break: break-all">{{ tag.topic }}</div>
                      </template>
                      <a-icon
                        v-show="tag.topic"
                        type="info-circle"
                        style="color: #fff; margin-right: 5px"
                        theme="filled"
                      />
                    </a-tooltip>
                    {{ tag.tagName }}
                  </a-tag>
                  <span style="color: #828282">{{ tag.topic }}</span>
                </div>
              </div>
            </div>
          </a-timeline-item>
        </a-timeline>
      </a-spin>
    </template>

    <template v-if="currentTab === '2'">
      <a-table
        style="margin-top: 10px"
        :columns="columns"
        :data-source="historyList"
        :loading="loading"
        :rowKey="(record) => record.id"
        :pagination="false"
      >
        <template slot="updateBefore" slot-scope="_, record">
          <div>{{ record.updateBefore.planPublishDate }}</div>
          <template
            v-if="
              record.updateBefore &&
              record.updateBefore.updateLogInfoList &&
              record.updateBefore.updateLogInfoList.length
            "
          >
            <div class="topic" v-for="(item, index) in record.updateBefore.updateLogInfoList" :key="index">
              <a-tag :color="item.colorHex">{{ item.tagName }}</a-tag>
              <span class="dot" :class="[getPublishState(item.publishStatus)]"></span
              >{{ getPublishText(item.publishStatus) }}
            </div>
          </template>
          <div v-else>-</div>
        </template>
        <template slot="updateAfter" slot-scope="_, record">
          <div>{{ record.updateAfter.planPublishDate }}</div>
          <template
            v-if="
              record.updateAfter && record.updateAfter.updateLogInfoList && record.updateAfter.updateLogInfoList.length
            "
          >
            <div class="topic" v-for="(item, index) in record.updateAfter.updateLogInfoList" :key="index">
              <a-tag :color="item.colorHex">{{ item.tagName }}</a-tag>
              <span class="dot" :class="[getPublishState(item.publishStatus)]"></span
              >{{ getPublishText(item.publishStatus) }}
            </div>
          </template>
          <div v-else>-</div>
        </template>
        <template slot="description" slot-scope="_, record">
          <a-tooltip :title="record.description">
            <div class="description-text">
              {{ record.description || '-' }}
            </div>
          </a-tooltip>
        </template>
      </a-table>
      <base-pagination
        :currentPage="pagination.current"
        :options="['10', '30', '50']"
        :pageSize="pagination.pageSize"
        :total="pagination.total"
        @onChange="getPlanHistoryList"
        @onShowSizeChange="getPlanHistoryList"
      />
    </template>

    <a-modal :visible="showRemark" title="填写主题或要素" :mask-closable="true" @cancel="handleRemarkClose">
      <div style="position: relative">
        <a-textarea
          :auto-size="{ minRows: 2, maxRows: 2 }"
          :maxLength="40"
          v-model.trim="remarkParams.topic"
          allowClear
          @pressEnter="handleSaveRemark"
        ></a-textarea>
        <span style="position: absolute; bottom: -20px; right: 0px; color: #8f8e8e; user-select: none"
          >{{ remarkParams.topic ? remarkParams.topic.length : 0 }}/40</span
        >
      </div>
      <template slot="footer">
        <a-button @click="handleRemarkClose">取消</a-button>
        <a-button type="primary" :loading="remarkLoading" @click="handleSaveRemark">确定</a-button>
      </template>
    </a-modal>
  </a-drawer>
</template>

<script>
import { personalityTypeObject } from '@/pages/app/xhsAgency/xhsMediaList/data.js';
import moment from 'moment';
import api from '@/api/xhsAgencyApi.js';

export default {
  data() {
    return {
      personalityTypeObject,
      visible: false,
      currentTab: '1',
      loading: false,

      openTimePanel: false,
      searchTimeRangeText: '',
      checkTimeRange: [],
      selectPriceDate: '',
      offsetDays: 2505600 * 1000, // 最多选择范围30天ms,
      timeRange: {
        本周: [moment().startOf('week'), moment().endOf('week')],
        本月: [moment().startOf('month'), moment().endOf('month')],
        最近30天: [moment().subtract(29, 'days'), moment()],
      },
      today: moment().format('YYYY-MM-DD'),
      showDropdown: false,

      params: {
        startDate: undefined,
        endDate: undefined,
      },
      detailInfo: {},

      record: {
        photoUrl:
          'https://cont-aweme-prod.oss-cn-hangzhou.aliyuncs.com/aweme_cover/xiaohongshu/80eb940cd9434929b824ed4e55fdbba8.jpeg?dataKey=1040g2jo30n65s7ktlk5g5p28nook4ij11uk1nrg',
        personalityType: 'SALE',
      },

      columns: [
        {
          align: 'left',
          title: '时间',
          dataIndex: 'ctime',
          width: 120,
        },
        {
          align: 'left',
          title: '操作人',
          width: 100,
          dataIndex: 'creator',
        },
        {
          align: 'left',
          title: '变更前',
          scopedSlots: { customRender: 'updateBefore' },
        },
        {
          align: 'left',
          title: '变更后',
          scopedSlots: { customRender: 'updateAfter' },
        },
        {
          align: 'left',
          title: '说明',
          dataIndex: 'description',
          width: 100,
          scopedSlots: { customRender: 'description' },
        },
      ],
      historyParams: {
        authorId: undefined,
        page: 1,
        size: 10,
      },
      pagination: {
        current: 1,
        pageSize: 10,
        total: 1,
      },
      historyList: [],
      showRemark: false,
      editParams: {},
      remarkParams: {
        id: undefined,
        topic: undefined,
      },
      remarkLoading: false,
    };
  },
  methods: {
    openDrawer(data) {
      Object.assign(this.$data, this.$options.data());
      this.visible = true;
      this.params = data;
      this.historyParams.authorId = data.authorId;
      this.onTimeChange([data.startDate, data.endDate]);
      // this.getPlanDetail();
    },
    handleClose() {
      this.visible = false;
    },
    handleTabChange(val) {
      if (val === '1') {
        this.getPlanDetail();
      } else {
        this.getPlanHistoryList();
      }
    },
    async getPlanDetail() {
      try {
        this.loading = true;
        const { code, data } = await api.getPlanDetail(this.params);
        this.loading = false;
        if (code === 200) {
          this.detailInfo = data;
        }
      } catch (error) {
        this.loading = false;
      }
    },
    async onTimeChange(dates, dateStrings) {
      this.selectPriceDate = '';
      this.checkTimeRange = [dates[0], dates[1]];
      dateStrings = dateStrings || [moment(dates[0]).format('YYYY-MM-DD'), moment(dates[1]).format('YYYY-MM-DD')];
      this.searchTimeRangeText = `${moment(dateStrings[0]).format('MM-DD')} ~ ${moment(dateStrings[1]).format(
        'MM-DD'
      )}`;
      if (
        dateStrings[0] === moment().startOf('week').format('YYYY-MM-DD') &&
        dateStrings[1] === moment().endOf('week').format('YYYY-MM-DD')
      ) {
        this.searchTimeRangeText = '本周';
      }
      if (
        dateStrings[0] === moment().startOf('month').format('YYYY-MM-DD') &&
        dateStrings[1] === moment().endOf('month').format('YYYY-MM-DD')
      ) {
        this.searchTimeRangeText = '本月';
      }
      if (
        dateStrings[0] === moment().subtract(29, 'days').format('YYYY-MM-DD') &&
        dateStrings[1] === moment().format('YYYY-MM-DD')
      ) {
        this.searchTimeRangeText = '最近30天';
      }
      this.showDropdown = false;
      this.openTimePanel = false;
      this.params.startDate = dateStrings[0];
      this.params.endDate = dateStrings[1];
      this.getPlanDetail();
    },
    // 选择完时间
    changePriceRangeDate() {
      this.selectPriceDate = '';
    },
    // 选择开始时间/结束时间
    calendarPriceRangeChange(date) {
      this.selectPriceDate = date[0];
    },
    // 根据选择的开始时间/结束时间，动态渲染要禁用的日期
    disabledPriceRangeDate(current) {
      if (this.selectPriceDate) {
        let selectV = moment(this.selectPriceDate, 'YYYY-MM-DD').valueOf();
        return (
          current > moment(new Date(selectV + this.offsetDays).getTime()) ||
          current < moment(new Date(selectV - this.offsetDays).getTime())
        );
      } else {
        return false;
      }
    },

    async getPlanHistoryList(page, size) {
      if (page) {
        this.historyParams.page = page;
        this.historyParams.size = size;
      } else {
        this.historyParams.page = 1;
      }
      try {
        this.loading = true;
        const { code, data } = await api.getPlanHistoryList(this.historyParams);
        this.loading = false;

        if (code === 200) {
          this.historyList = data.list.map((item) => ({
            ...item,
            ctime: moment(item.ctime).format('YYYY-MM-DD HH:mm'),
          }));
          this.pagination.total = data.total;
        }
      } catch (error) {
        this.loading = false;
      }
    },
    getPublishState(state) {
      let result = '';
      switch (state) {
        case 'NOT_PUBLISH':
          result = 'gray';
          break;
        case 'PUBLISHED':
          result = 'green';
          break;
        case 'EXPIRED':
          result = 'red';
          break;
      }
      return result;
    },
    getPublishText(state) {
      let result = '';
      switch (state) {
        case 'NOT_PUBLISH':
          result = '待发布';
          break;
        case 'PUBLISHED':
          result = '已发布';
          break;
        case 'EXPIRED':
          result = '已过期';
          break;
      }
      return result;
    },
    handleEdit({ objectId, topic }) {
      this.showRemark = true;
      this.remarkParams.id = objectId;
      if (topic) this.remarkParams.topic = topic;
    },
    async handleSaveRemark() {
      this.remarkLoading = true;
      const params = {
        ...this.remarkParams,
      };
      if (!params.topic) params.params = '';
      const { code, message } = await api.addXhsPromotionInfoRemark(params).finally(() => {
        this.remarkLoading = false;
      });
      if (code === 200) {
        this.getPlanDetail();
        this.$emit('refresh');
        this.$message.success('修改成功');
        this.handleRemarkClose();
      } else {
        this.$message.error(message);
      }
    },
    handleRemarkClose() {
      this.remarkLoading = false;
      this.showRemark = false;
      this.remarkParams = this.$options.data().remarkParams;
    },
  },
  created() {},
};
</script>

<style lang="scss">
.plan-detail-dropdown .ant-dropdown-menu {
  width: 0;
}
</style>
<style scoped lang="scss">
::v-deep .ant-drawer-wrapper-body {
  display: flex;
  flex-direction: column;
  overflow: hidden;
}
::v-deep .ant-drawer-body {
  flex: 1;
  overflow-y: auto;
  padding-top: 0;
}

.media-column {
  display: flex;
  margin-top: 10px;
  text-align: left;

  .media-left {
    width: 50px;
    height: 50px;
    border-radius: 25px;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
    }
  }
  .avatar-left {
    width: 50px;
    height: 50px;
    margin-right: 15px;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .media-right {
    flex: 1;

    .right-txt {
      display: inline-flex;
      justify-content: start;
      align-items: center;

      .logo {
        width: 20px;
        height: 20px;
        border-radius: 10px;
        overflow: hidden;

        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
  .avatar-right {
    flex: 1;
    display: flex;
    align-items: center;
  }
}

.title-wrapper {
  margin-top: 24px;
  & > span {
    font-size: 16px;
    font-weight: bold;
    margin-right: 10px;
  }
}

.count-list {
  margin-top: 15px;
  margin-bottom: 20px;
}

.time-line {
  display: flex;
  .time {
    flex-shrink: 0;
    margin-right: 10px;
  }
}

.topic {
  margin-top: 4px;
}

.dot {
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 10px;
  margin-right: 4px;
  &.gray {
    background: #d9d9d9;
  }
  &.green {
    background: #52c41a;
  }
  &.red {
    background: #f5222d;
  }
}
.description-text {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
  word-break: break-all;
  cursor: pointer;
}
</style>
