<template>
  <a-drawer
    :title="drawerTitle"
    width="620"
    :visible="visible"
    @close="onClose"
    :destroyOnClose="true"
    :maskClosable="false"
  >
    <template v-if="drawerType === 'planList'">
      <a-input-search
        style="margin-bottom: 10px; width: 98%"
        placeholder="输入模板名称搜索"
        allowClear
        v-model="searchName"
        @search="getPlanList"
      ></a-input-search>
      <a-spin :spinning="listSpinning">
        <div class="plan_list_wrap">
          <div class="plan_list_item" v-for="(list, index) in planList" :key="index">
            <div class="between_flex">
              <span style="font-size: 16px; font-weight: bold">{{ list.notePublishPlanTemplateName }}</span>
              <span
                >更新时间：{{ list.mtime }}
                <a-button type="link" @click="list.showMore = !list.showMore"
                  >{{ list.showMore ? '收起' : '展开' }} <a-icon :type="list.showMore ? 'up' : 'down'" /> </a-button
              ></span>
            </div>
            <template v-if="list.showMore">
              <div style="margin: 10px 0; height: 1px; background-color: #e1e1e1"></div>
              <div style="margin-bottom: 10px; padding: 0 20px" v-for="(val, i) in list.templateWeekInfoArr" :key="i">
                <div style="font-size: 16px">第{{ i + 1 }}周</div>
                <div v-for="(d, n) in val" :key="n" class="tag_rows">
                  <span>周{{ cnWeekList[n] }}</span>
                  <div class="tags">
                    <a-tag style="margin-bottom: 5px" v-for="tag in d.topicSelectionTagList" :key="tag.id">
                      {{ tag.tagName }}</a-tag
                    >
                  </div>
                  <!-- <a-select mode="multiple" style="width: 90%" >
                    <a-select-option v-for="tag in d.topicSelectionTagList" :key="tag.id">
                      {{ tag.tagName }}
                    </a-select-option>
                  </a-select> -->
                  <!-- <a-tag v-for="tag in d.topicSelectionTagList" :key="tag.id">{{ tag.tagName }}</a-tag> -->
                </div>
              </div>
              <div class="between_flex">
                <span>
                  <a-button type="link" icon="up" @click="list.showMore = !list.showMore" style="color: #aaa"
                    >收起</a-button
                  >
                  <a-button type="link" @click="handlePlanEdit(list)">编辑</a-button>
                  <a-button type="link" @click="handlePlanUse(list)">应用</a-button>
                </span>
                <a-popconfirm
                  placement="top"
                  title="确定删除该计划？"
                  ok-text="确定"
                  cancel-text="取消"
                  @confirm="handleDelPlan(list)"
                >
                  <a-button style="color: #f00" type="link"> 删除 </a-button>
                </a-popconfirm>
              </div>
            </template>
          </div>
        </div>
      </a-spin>
      <div
        :style="{
          position: 'absolute',
          display: 'flex',
          bottom: 0,
          width: '100%',
          borderTop: '1px solid #e8e8e8',
          padding: '10px 16px',
          textAlign: 'left',
          left: 0,
          background: '#fff',
          borderRadius: '0 0 4px 4px',
          zIndex: 2,
          justifyContent: 'space-between',
        }"
      >
        <a-button type="primary" @click="handleAddPlan">添加</a-button>
      </div>
    </template>
    <template v-if="drawerType === 'planUpdate'">
      <a-spin :spinning="editSpinning">
        <div class="plan_list_wrap" style="max-height: 82vh">
          <div>
            <span><span style="color: #da0722">*</span>模板名称</span
            ><a-input
              style="margin-left: 20px; width: 80%"
              v-model="updataParams.notePublishPlanTemplateName"
            ></a-input>
          </div>
          <div style="margin-top: 15px" v-for="(week, index) in updataParams.templateWeekInfoArr" :key="index">
            <div style="font-size: 16px">
              第{{ index + 1 }}周
              <!-- <a-button type="link" v-show="index != 0" @click="handlePlanDel(week.uid)">删除</a-button> -->
            </div>
            <div v-for="(d, i) in cnWeekList" :key="i">
              <span>周{{ d }}</span>
              <TopicTreeInput
                ref="TopicTreeInput"
                v-model="week[i].tagId"
                show-search
                style="width: 80%; margin: 0 0 8px 48px"
                :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
                :replaceFields="{ title: 'name' }"
                placeholder="选题"
                multiple
                :maxTagCount="3"
                allow-clear
                tree-default-expand-all
                :isAccept="true"
                :acceptList="tagList"
                @input="(select) => getTagData(select, week[i])"
              />
            </div>
          </div>
          <a-button
            type="dashed"
            icon="plus"
            style="margin: 20px auto 0; width: 100%"
            @click="updataParams.templateWeekInfoArr.push(getNewWeekData())"
          >
            一周
          </a-button>
        </div>
      </a-spin>
      <div
        :style="{
          position: 'absolute',
          display: 'flex',
          bottom: 0,
          width: '100%',
          borderTop: '1px solid #e8e8e8',
          padding: '10px 16px',
          textAlign: 'left',
          left: 0,
          background: '#fff',
          borderRadius: '0 0 4px 4px',
          zIndex: 2,
          justifyContent: 'space-between',
        }"
      >
        <a-space>
          <a-button type="primary" :loading="updateLoading" @click="handlePlanSave">保存</a-button>
          <a-button @click="handleBackToList">取消</a-button>
        </a-space>
      </div>
    </template>
    <template v-if="drawerType === 'planUse'">
      <a-form-model
        ref="usePlanModel"
        :label-col="{ span: 5 }"
        :wrapper-col="{ span: 15 }"
        :model="usePlanForm"
        :rules="usePlanRules"
      >
        <a-form-model-item label="账号" required>
          <a-radio-group v-model="usePlanForm.accountType">
            <a-radio value="SINGLE" key="SINGLE">单个账号</a-radio>
            <a-radio value="GROUP" key="GROUP">账号分组</a-radio>
          </a-radio-group>
        </a-form-model-item>
        <template v-if="usePlanForm.accountType === 'SINGLE'">
          <div style="width: 60%; margin: 0 auto">
            <a-select v-model="usePlanForm.authorId" placeholder="请选择账号" @change="handleMeidaChange">
              <a-select-option v-for="val in mediaList" :key="val.authorId" :value="val.authorId">{{
                val.nickname
              }}</a-select-option>
            </a-select>
            <div class="media-column" v-show="usePlanForm.authorId">
              <div class="media-left" v-show="currentMedia.avatar">
                <img :src="currentMedia.avatar" alt="头像" referrerpolicy="no-referrer" />
              </div>
              <div class="media-right">
                <div style="margin-left: 10px">{{ currentMedia.nickname }}</div>
                <div style="display: flex; align-items: center">
                  <span class="logo"><img src="@/assets/icon/xhs_logo.png" alt="" /></span
                  ><span>{{ currentMedia.authorCode }}</span>
                </div>
              </div>
            </div>
          </div>
        </template>
        <template v-else>
          <div style="width: 60%; margin: 0 auto">
            <a-select v-model="usePlanForm.groupId" placeholder="请选择分组">
              <a-select-option v-for="val in groupList" :key="val.id" :value="val.id">{{
                val.groupName
              }}</a-select-option>
            </a-select>
          </div>
        </template>
        <a-form-model-item label="模板名称" required>
          <a-select v-model="usePlanForm.templateId" placeholder="请选择模板" @change="handleTemplateChange">
            <a-select-option v-for="val in planList" :key="val.id" :value="val.id">{{
              val.notePublishPlanTemplateName
            }}</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="起始日期" required>
          <a-date-picker
            :allowClear="false"
            style="width: 100%"
            v-model="usePlanForm.startDate"
            format="YYYY/MM/DD"
            @change="() => {}"
          />
        </a-form-model-item>
      </a-form-model>
      <div style="width: 90%; margin: 0 auto">
        <a-checkbox v-model="usePlanForm.overwriteFlag">
          清除起始日期后已制定的运营计划（已发布、已推送的计划不会清除）
        </a-checkbox>
      </div>
      <a-divider></a-divider>
      <a-spin :spinning="editSpinning">
        <div class="plan_list_wrap" :style="`max-height: ${usePlanForm.authorId ? '48vh' : '56vh'}`">
          <div class="use_plan_item" v-for="(week, index) in updataParams.templateWeekInfoArr" :key="index">
            <div style="font-size: 16px">第{{ index + 1 }}周</div>
            <div v-for="(d, n) in week" :key="n" class="tag_rows">
              <span>周{{ cnWeekList[n] }}</span>
              <div class="tags">
                <a-tag style="margin-bottom: 5px" v-for="tag in d.topicSelectionTagList" :key="tag.id">
                  {{ tag.tagName }}</a-tag
                >
              </div>
            </div>
          </div>
        </div>
      </a-spin>
      <div
        :style="{
          position: 'absolute',
          display: 'flex',
          bottom: 0,
          width: '100%',
          borderTop: '1px solid #e8e8e8',
          padding: '10px 16px',
          textAlign: 'left',
          left: 0,
          background: '#fff',
          borderRadius: '0 0 4px 4px',
          zIndex: 2,
          justifyContent: 'space-between',
        }"
      >
        <a-space>
          <a-button type="primary" :loading="useLoading" @click="handleUseTemplate">应用</a-button>
          <a-button @click="handleBackToList">取消</a-button>
        </a-space>
      </div>
    </template>
  </a-drawer>
</template>

<script>
import TopicTreeInput from '@/components/xhsAgencyTool/TopicTreeInput';
import moment from 'moment';
import api from '@/api/xhsAgencyApi';

export default {
  components: { TopicTreeInput },
  data() {
    const cnWeekList = ['一', '二', '三', '四', '五', '六', '日'];
    const usePlanRules = {};
    return {
      cnWeekList,
      visible: false,
      drawerTitle: '添加计划',
      drawerType: 'planList', // planList-模板列表 planUpdate-模板添加/编辑 planUse-模板应用
      searchName: undefined,
      planList: [],
      listSpinning: false,
      editSpinning: false,
      updataParams: { id: undefined, notePublishPlanTemplateName: undefined, templateWeekInfoArr: [] },
      usePlanRules,
      usePlanForm: {
        accountType: 'SINGLE', // GROUP
        overwriteFlag: false,
        startDate: null,
        authorId: undefined,
        templateId: undefined,
        groupId: undefined,
      },
      currentMedia: {},
      mediaList: [],
      groupList: [],
      templateList: [],
      detailPlanList: [
        {
          text: '周一',
          tag: '固定涨粉选题',
        },
      ],
      updateLoading: false,
      tagList: [],
      tagListFlat: [],
      useLoading: false,
    };
  },
  created() {},
  methods: {
    getPlanList() {
      const params = {
        templateName: this.searchName,
        page: 1,
        size: 9999,
      };
      this.listSpinning = true;
      this.handleRequest(
        'getPlanTemplateList',
        (data) => {
          this.planList = data.list.map((v) => {
            v.showMore = false;
            return v;
          });
        },
        params
      ).finally(() => (this.listSpinning = false));
    },
    handleUseWeekPlan(data) {
      this.drawerTitle = '添加计划';
      this.updataParams.templateWeekInfoArr.push(this.getNewWeekData());
      data.weekData.forEach((week, index) => {
        if (week.topicSelectionTagList.length > 0) {
          this.updataParams.templateWeekInfoArr[0][index].topicSelectionTagList = week.topicSelectionTagList.map(
            (val) => {
              return {
                colorHex: val.colorHex,
                id: val.id,
                tagName: val.tagName,
              };
            }
          );
          this.updataParams.templateWeekInfoArr[0][index].tagId = week.topicSelectionTagList.map((val) => val.id);
        }
      });
    },
    handlePlanEdit({ id }) {
      this.drawerType = 'planUpdate';
      this.drawerTitle = '编辑计划';
      this.getEditData(id);
    },
    handlePlanUse({ id }) {
      this.drawerType = 'planUse';
      this.drawerTitle = '应用计划模版';
      this.getMediaList();
      this.getPlanList();
      if (id) {
        this.usePlanForm.templateId = id;
        this.getEditData(id);
      }
      const currentWeek = moment().week(Number);
      const year = moment(currentWeek).get('year');
      const week = moment(currentWeek).get('week');
      const startDate = moment(`${year}-${week}`, 'GGGG-WW');
      this.usePlanForm.startDate = startDate;
    },
    handleDelPlan({ id }) {
      this.handleRequest(
        'delPlanTemplate',
        () => {
          this.$message.success('删除成功');
          this.getPlanList();
        },
        id
      );
    },
    getEditData(id) {
      this.editSpinning = true;
      this.handleRequest(
        'getPlanTemplateDetail',
        (data) => {
          const _data = JSON.parse(JSON.stringify(data));
          _data.templateWeekInfoArr.forEach((val) => {
            val.forEach((days) => {
              const tagId = [];
              days.topicSelectionTagList.forEach((tags) => {
                tagId.push(tags.id);
              });
              days.tagId = tagId;
            });
          });
          this.updataParams = _data;
        },
        id
      ).finally(() => (this.editSpinning = false));
    },
    // handlePlanDel(uid) {
    //   const index = this.updataParams.weekList.findIndex((week) => week.uid === uid);
    //   if (index != -1) {
    //     this.updataParams.weekList.splice(index, 1);
    //   }
    // },
    handleBackToList() {
      this.drawerType = 'planList';
      this.drawerTitle = '模板列表';
      this.getPlanList();
      this.updataParams = { id: undefined, notePublishPlanTemplateName: undefined, templateWeekInfoArr: [] };
      this.usePlanForm = {
        accountType: 'SINGLE',
        overwriteFlag: false,
        startDate: null,
        authorId: undefined,
        templateId: undefined,
        groupId: undefined,
      };
      this.currentMedia = {};
    },
    handlePlanSave() {
      if(!this.updataParams.notePublishPlanTemplateName) return this.$message.info('标题不得为空')
      this.updateLoading = true;
      this.handleRequest(
        'updatePlanTemplate',
        () => {
          this.$message.success('操作成功');
          this.handleBackToList();
        },
        this.updataParams
      ).finally(() => {
        this.updateLoading = false;
      });
      console.log(this.updataParams);
    },
    async getAuthorListFromGroup(groupId) {
      const params = {
        groupId,
        pageNum: 1,
        pageSize: 9999,
        groupScene: 6,
      };
      const { code, data } = await api.getGroupMediaList(params);
      if (code === 200) {
        return data.list.map((val) => val.authorId);
      } else {
        return [];
      }
    },
    async handleUseTemplate() {
      let authorIdList = [];
      if (this.usePlanForm.accountType === 'SINGLE') {
        authorIdList = [this.usePlanForm.authorId];
      } else {
        authorIdList = await this.getAuthorListFromGroup(this.usePlanForm.groupId);
        if (!authorIdList.length) return this.$message.info('该分组下没有媒体号');
      }
      const params = {
        authorIdList,
        notePublishTemplateId: this.usePlanForm.templateId,
        startDate: moment(this.usePlanForm.startDate).format('YYYY-MM-DD'),
        overwriteFlag: this.usePlanForm.overwriteFlag,
      };
      console.log(params);
      if (!authorIdList.length) return this.$message.info('请选择账号或分组');
      if (!params.startDate) return this.$message.info('请选择起始日期');
      if (!params.notePublishTemplateId) return this.$message.info('请选择计划模板');
      this.useLoading = true;
      this.handleRequest(
        'usePlanTemplate',
        () => {
          this.$message.success('操作成功');
          this.$emit('useTemplate');
          this.onClose();
        },
        params
      ).finally(() => (this.useLoading = false));
    },
    getTagData(val, templateWeekInfoArr) {
      let topicSelectionTagList = [];
      this.tagListFlat.forEach((tag) => {
        val?.length &&
          val.forEach((t) => {
            if (t === tag.id) {
              topicSelectionTagList.push({
                colorHex: tag.colorHex,
                id: tag.id,
                tagName: tag.tagName,
              });
            }
          });
      });
      templateWeekInfoArr.topicSelectionTagList = topicSelectionTagList;
    },
    handleTemplateChange(id) {
      if (id) {
        this.getEditData(id);
      } else {
        this.updataParams = { id: undefined, notePublishPlanTemplateName: undefined, templateWeekInfoArr: [] };
      }
    },
    getNewWeekData() {
      return [
        { weekDay: 'MONDAY', tagId: [], topicSelectionTagList: [] },
        { weekDay: 'TUESDAY', tagId: [], topicSelectionTagList: [] },
        { weekDay: 'WEDNESDAY', tagId: [], topicSelectionTagList: [] },
        { weekDay: 'THURSDAY', tagId: [], topicSelectionTagList: [] },
        { weekDay: 'FRIDAY', tagId: [], topicSelectionTagList: [] },
        { weekDay: 'SATURDAY', tagId: [], topicSelectionTagList: [] },
        { weekDay: 'SUNDAY', tagId: [], topicSelectionTagList: [] },
      ];
    },
    async saveMonthPlan(params) {
      const hide = this.$message.loading('请稍后...', 0);
      await this.getAllTagId();
      this.handleRequest(
        'makePlanTemplate',
        (data) => {
          this.drawerTitle = '添加计划';
          this.drawerType = 'planUpdate';
          this.visible = true;
          data.templateWeekInfoArr.forEach((week) => {
            if (week.length !== 7) {
              const newWeek = [
                { weekDay: 'MONDAY', tagId: [], topicSelectionTagList: [] },
                { weekDay: 'TUESDAY', tagId: [], topicSelectionTagList: [] },
                { weekDay: 'WEDNESDAY', tagId: [], topicSelectionTagList: [] },
                { weekDay: 'THURSDAY', tagId: [], topicSelectionTagList: [] },
                { weekDay: 'FRIDAY', tagId: [], topicSelectionTagList: [] },
                { weekDay: 'SATURDAY', tagId: [], topicSelectionTagList: [] },
                { weekDay: 'SUNDAY', tagId: [], topicSelectionTagList: [] },
              ];
              const _week = JSON.parse(JSON.stringify(week));
              newWeek.forEach((t) => {
                _week.forEach((o) => {
                  if (t.weekDay === o.weekDay) {
                    t.topicSelectionTagList = o.topicSelectionTagList;
                    t.tagId = o.topicSelectionTagList.map((tag) => tag.id);
                  }
                });
              });
              week = newWeek;
            } else {
              week.forEach((day) => {
                day.tagId = [];
                if (day.topicSelectionTagList.length) {
                  day.tagId = day.topicSelectionTagList.map((tag) => tag.id);
                }
              });
            }
            this.updataParams.templateWeekInfoArr.push(week);
          });
        },
        params
      ).finally(() => hide());
    },
    async openDrawer(type, extraData = {}) {
      this.drawerType = type;
      this.getGroupList();
      await this.getAllTagId();
      if (type === 'planList') {
        this.getPlanList();
      }
      if (type === 'planUpdate') {
        this.handleUseWeekPlan(extraData);
      }
      if (type === 'planUse') {
        this.handlePlanUse({});
        this.usePlanForm.authorId = extraData.authorId;
        this.usePlanForm.startDate = extraData.startDate;
        this.currentMedia = extraData.mediaData;
      }
      this.visible = true;
    },
    getGroupList() {
      const params = {
        pageNum: 1,
        pageSize: 9999,
        groupScene: 6,
      };
      this.handleRequest(
        'getMediaGroupList',
        (data) => {
          this.groupList = data.list;
        },
        params
      ).finally(() => (this.tableLoading = false));
    },
    onClose() {
      this.visible = false;
      this.updataParams = { id: undefined, notePublishPlanTemplateName: undefined, templateWeekInfoArr: [] };
      this.usePlanForm = {
        accountType: 'SINGLE',
        overwriteFlag: false,
        startDate: null,
        authorId: undefined,
        templateId: undefined,
        groupId: undefined,
      };
      this.currentMedia = {};
    },

    handleAddPlan() {
      this.drawerType = 'planUpdate';
      this.drawerTitle = '添加计划';
      this.updataParams.templateWeekInfoArr.push(this.getNewWeekData());
    },
    handleMeidaChange(authorId) {
      this.currentMedia = this.mediaList.find((media) => media.authorId === authorId);
    },

    async handleRequest(APINAME, callbackFn, PARAMS = null) {
      const { code, data, message } = await api[APINAME](PARAMS);
      if (code === 200) {
        callbackFn(data);
      } else {
        return this.$message.error(`${message}`);
      }
    },

    // 获取媒体号列表
    async getMediaList() {
      const { code, data } = await api.getPlanMediaList({
        page: 1,
        size: 200,
      });
      if (code === 200) {
        this.mediaList = data.list;
      }
    },
    // 获取全部选题
    async getAllTagId() {
      const { code, data, message } = await api.getTopicTagTree(0);
      if (code === 200) {
        this.tagList = data.map(this.initTagValue);
        this.tagListFlat = this.flattenTree(this.tagList);
      } else {
        return this.$message.error(`${message}`);
      }
    },
    // 选题初始化
    initTagValue(data) {
      if (data.children && data.children.length > 0) {
        data.children = data.children.map(this.initTagValue);
      }
      return {
        ...data,
        name: data.tagName,
        value: data.id,
        key: data.id,
        disabled: data.id === this.originId,
      };
    },
    // 选题降维
    flattenTree(tree) {
      const result = [];
      function traverse(node) {
        result.push(node);
        if (node.children) {
          for (const child of node.children) {
            traverse(child);
          }
        }
      }
      for (const item of tree) {
        traverse(item);
      }

      return result;
    },
  },
};
</script>

<style lang="less" scoped>
.plan_list_wrap {
  padding-right: 10px;
  max-height: 79vh;
  overflow-y: auto;

  .plan_list_item {
    margin-bottom: 10px;
    padding: 10px;
    border: 1px solid #e1e1e1;
    border-radius: 3px;
  }

  .between_flex {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.tag_rows {
  display: flex;
  align-items: center;
  margin-bottom: 6px;

  .tags {
    flex: 1;
    display: flex;
    padding: 5px 5px 0 5px;
    margin-left: 44px;
    border-radius: 5px;
    border: 1px solid #ccc;
    min-height: 30px;
    flex-wrap: wrap;
    align-items: center;
  }
}

.media-column {
  display: flex;
  margin-top: 10px;
  text-align: left;

  .media-left {
    width: 50px;
    height: 50px;
    border-radius: 25px;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .media-right {
    flex: 1;

    .right-txt {
      justify-content: start;
    }
  }

  .logo {
    width: 20px;
    height: 20px;
    border-radius: 10px;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
    }
  }
}

.use_plan_item {
  margin-bottom: 18px;
}

/deep/ .ant-form-item {
  margin-bottom: 8px;
}

&::-webkit-scrollbar {
  width: 10px;
  height: 10px;
  border-radius: 10px;
}

&::-webkit-scrollbar-track {
  background: #fff;
  border-radius: 10px;
}

&::-webkit-scrollbar-thumb {
  background: #00152984;
  border-radius: 10px;
}

&::-webkit-scrollbar-thumb:hover {
  background: #0015294c;
}

&::-webkit-scrollbar-corner {
  background: #fff;
}
</style>
